<template>
    <div class="top_bar ls_flexalign_center">
        <div class="ls_flex_1">
            老师同学好！体验天数剩余：
            <span v-if="day >= 0">{{ day }}</span>
            天！ 延长体验请联系商务哦！
        </div>
        <div>商务咨询：13635275043</div>
        <div class="ls_flexalign_center" v-if="memberInfo">
            <img
                :src="(memberInfo.avatar || 'avatar/default.png') | imgFormat"
            />
            <div>{{ memberInfo.nickName }}</div>
            <el-dropdown @command="handleCommand">
                <i class="iconfont icon-zhankai"></i>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="1">账号管理</el-dropdown-item>
                    <el-dropdown-item command="2">退出账号</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import { delCookie } from '@/plugins/utils';
export default {
    data() {
        return {
            memberInfo: '',
            day: 0,
        };
    },
    created() {
        this.$ajax.post('/user/user/getInfo').then(res => {
            if (res.msg === '教师审核未通过，请联系管理员') {
                this.$router.replace({ path: '/err/examine' });
                return;
            }
            this.memberInfo = res.data;
            const newDate = new Date();
            this.day = dayjs(res.data.organizationExpireTime).diff(
                dayjs(newDate),
                'day'
            );
            if (
                dayjs(res.data.organizationExpireTime).diff(
                    dayjs(newDate),
                    'second'
                ) < 0
            ) {
                this.$router.replace({ path: '/err/expire' });
                return;
            }
            this.$store.dispatch('setMemberInfoAsync', res.data || {});
            this.$emit('load');
        });
    },
    methods: {
        clickItem(item) {
            console.log(item);
        },
        handleCommand(type) {
            if (type === '2') {
                this.outLogin();
            } else {
                this.$router.push({ path: '/account' });
            }
        },
        outLogin() {
            this.$ajax.delete('/login/logout').then(res => {
                localStorage.removeItem('access_token');
                localStorage.removeItem('authToken');
                delCookie('flower_info');
                this.$router.replace({
                    path: '/login',
                });
            });
        },
    },
};
</script>
<style lang="scss">
.expire_tip {
    text-align: center;
    > div {
        &:first-child {
            font-size: 18px;
            line-height: 1.3em;
        }
        &:last-child {
            font-size: 14px;
            line-height: 1.3em;
            margin-top: 15px;
        }
    }
}
</style>
<style lang="scss" scoped>
.top_bar {
    height: 70px;
    width: calc(100vw - 284px);
    color: #6d7381;
    border-bottom: 2px solid #6d7381;
    > div {
        height: 100%;
        line-height: 70px;
        &:nth-child(2) {
            padding: 0 34px;
        }
        &:nth-child(3) {
            padding: 0 30px 0 21px;
            img {
                width: 36px;
                height: 36px;
                border-radius: 100%;
                margin-right: 13px;
            }
            > div {
                margin-right: 35px;
                width: 75px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        + div {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                width: 1px;
                height: 48px;
                background: #e7e7e7;
                left: 0;
                top: 11px;
            }
        }
    }
}
</style>
